<template>
	<div class="row">
      <div class="col-12">
			<div class="card mb-2 homeBg">
				<div class="card-body p-12 text-center text-sm-start">
               <div class="wpx-350">
                  <img src="@/assets/admin/images/logo-img.png" alt="Mooli Logo" class="mx-sm-4 w-100">
               </div>
				</div>
			</div>
		</div>

      <div class="col-12">
			<div class="grid grid-custom cards-225 cards-md-350">
				<router-link to="/admBlog" class="card">
					<span class="icon"><i class="fal fa-rss"></i></span>
					<h4>&nbsp;&nbsp;Blog</h4>
					<p class="text-secondary">&nbsp;&nbsp;Crie notícias e hotsites para produtos.</p>
					<div class="shine"></div>
					<div class="background">
						<div class="tiles">
							<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
							<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
						</div>
						<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
					</div>
				</router-link>
			</div>
		</div>
   </div> 
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'AdmPágina_Inicial',
	computed: {
		... mapState({
         dadosUsuario: state => state.dadosUsuario,
			logado: state => state.logado
		})
	}
}

</script>

<style scoped>

.row {
	margin: 0;
}

.row>div {
	padding: 0;
}

.cards {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
   gap: 0.5rem;
   padding: 0;
   list-style-type: none;
}

</style>